
import React from 'react';

const SEOApproachDE = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-6 text-brand-heading text-center">
            Entwickelt für Google – und für Ihre Kunden
          </h2>
          
          <div className="bg-white p-8 rounded-lg shadow-sm border border-gray-100">
            <p className="text-lg mb-6 text-brand-text">
              Wir schaffen die inhaltliche, strukturelle und technische Grundlage für langfristige Sichtbarkeit. Keine Tricks – sondern nachhaltige Strategien.
            </p>
            
            <p className="text-lg text-brand-text">
              Dazu gehören lokale SEO-Optimierung, technische Analyse, Keyword-Strategie und kontinuierliche Inhaltspflege. Bei Bedarf übernehmen wir auch Backlink-Aufbau und Rankings-Tracking – damit Ihre Seite dauerhaft gefunden wird.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SEOApproachDE;
