
import React from 'react';
import { Separator } from '@/components/ui/separator';

const SEOTextSection = () => {
  return (
    <section className="bg-[#f7fafa] pt-12 pb-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <article className="prose prose-lg max-w-none text-[17px] leading-relaxed text-gray-700">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">Ihre Werbeagentur in Mainz – Maßgeschneiderte digitale Lösungen für Ihren Erfolg</h2>
          <p>
            Seit über 16 Jahren ist ooliv Ihre verlässliche Werbeagentur in Mainz, die kreative Lösungen, technische Expertise und durchdachte Online-Marketing-Strategien vereint. Unser Ziel ist es, Ihre Marke in der digitalen Welt optimal zu positionieren. Ob Webdesign, individuelle Programmierlösungen oder umfassende Marketingkampagnen – wir setzen Ihre Ideen in erfolgreiche Projekte um.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Ihre Designagentur in Mainz – Kreativität trifft auf Innovation</h3>
          <p>
            Unsere Designagentur in Mainz setzt auf eine einzigartige Verbindung aus Kreativität und Innovation. Jedes Projekt wird individuell entwickelt, um Ihre Marke mit einem ästhetischen und funktionalen Design zu stärken. Mit einem erfahrenen Team aus kreativen Köpfen und technischem Know-how schaffen wir Designs, die inspirieren und überzeugen. Dabei berücksichtigen wir nicht nur aktuelle Trends, sondern auch die spezifischen Anforderungen Ihrer Branche und Zielgruppe.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Herausforderungen und Strategien beim Agenturwechsel</h3>
          <p>
            Ein Wechsel der Werbeagentur bringt häufig Herausforderungen mit sich, wie die Integration bestehender Projekte, das Vertrauen in neue Partner oder das Verständnis der Markenidentität. Wir bei ooliv kennen diese Herausforderungen und bieten Ihnen bewährte Strategien, um den Übergang reibungslos zu gestalten:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Analyse Ihrer bisherigen Agenturprojekte: Wir prüfen, welche Ansätze erfolgreich waren und welche verbessert werden können.</li>
            <li>Klare Kommunikationswege: Transparenz und regelmäßiger Austausch sind für einen erfolgreichen Wechsel entscheidend.</li>
            <li>Risikominimierung: Mit einer sorgfältigen Planung und Übergabeprozessen stellen wir sicher, dass keine wichtigen Daten oder Ressourcen verloren gehen.</li>
          </ul>
          <p>
            Ein Wechsel kann eine Chance sein, Ihre Marke neu zu positionieren und von frischen, innovativen Ideen zu profitieren. Vertrauen Sie auf unsere Erfahrung und regionale Marktkenntnisse in Mainz.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Erfolgsanalysen und Fallstudien – Überzeugende Ergebnisse</h3>
          <p>
            Unsere Arbeit spricht für sich: Dank detaillierter Mitbewerberanalysen und datengetriebener Strategien konnten wir bereits zahlreiche Kunden in Mainz und darüber hinaus zum Erfolg führen. Hier ein Beispiel:
          </p>
          <p>
            <strong>Fallstudie: Lokale SEO-Strategie für einen Einzelhändler</strong> Durch gezielte Keyword-Optimierungen und eine lokale SEO-Kampagne erhöhte sich die Sichtbarkeit um 45 % innerhalb von sechs Monaten. Dies führte zu einer Verdopplung des Umsatzes im Online-Shop.
          </p>
          <p>
            Unsere Erfolgsberichte und Kundenbewertungen zeigen, wie wir individuelle Strategien entwickeln, um Ihre Ziele zu erreichen.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Webdesign Mainz – Kreative und innovative Lösungen</h3>
          <p>
            Ihre Website ist die digitale Visitenkarte Ihres Unternehmens. Als erfahrene Webdesign-Agentur in Mainz entwickeln wir Websites, die:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Benutzerfreundlich und responsive sind.</li>
            <li>SEO-optimiert und für Google ausgerichtet sind.</li>
            <li>Kreativität und Innovation in den Vordergrund stellen.</li>
          </ul>
          <p>
            Unsere Designs integrieren die kreative DNA von Mainz – eine einzigartige Verbindung aus Tradition und Moderne. Wir entwickeln maßgeschneiderte Websites, die Ihre Vision widerspiegeln und Ihre Zielgruppe begeistern.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">SEO und Online-Marketing – Sichtbarkeit für Ihren Erfolg</h3>
          <p>
            Eine schöne Website reicht nicht aus. Deshalb sorgen wir dafür, dass Ihre Zielgruppe Sie auch findet. Unsere Leistungen umfassen:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Keyword-Analyse: Identifikation relevanter Begriffe für Ihre Branche.</li>
            <li>On-Page-Optimierung: Optimierung von Texten, Bildern und Ladezeiten.</li>
            <li>Off-Page-Strategien: Aufbau hochwertiger Backlinks.</li>
            <li>Lokale SEO: Maximale Präsenz im Mainzer Markt.</li>
          </ul>
          <p>
            Durch kanalübergreifende Strategien verbinden wir verschiedene digitale Kanäle, um Ihre Online-Präsenz ganzheitlich zu stärken. Ob Webdesign oder Suchmaschinenoptimierung – wir stellen sicher, dass alle Maßnahmen miteinander harmonieren und optimale Ergebnisse erzielen.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Google Ads – Schnelle Ergebnisse mit messbarem Erfolg</h3>
          <p>
            Mit Google Ads sprechen wir Ihre Zielgruppe genau dort an, wo sie sich befindet. Unsere datengetriebenen Kampagnen maximieren Ihre Reichweite:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Gezielte Anzeigen für Ihre Zielgruppe.</li>
            <li>Budgetkontrolle für optimale Effizienz.</li>
            <li>Laufende Optimierungen zur Performance-Steigerung.</li>
          </ul>
          <p>
            Egal, ob Sie Ihre Markenbekanntheit steigern, mehr Leads generieren oder direkt Umsatz erzielen möchten – wir entwickeln Google Ads-Kampagnen, die Ihre individuellen Ziele erreichen.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Branding und Corporate Design – Ihre Marke, klar und unverwechselbar</h3>
          <p>
            Ein starkes Branding schafft Vertrauen und Wiedererkennung. Wir entwickeln individuelle Markenidentitäten, die Ihre Werte und Visionen widerspiegeln. Vom Logo-Design bis zur Markenstrategie sorgen wir dafür, dass Ihre Marke einzigartig ist.
          </p>
          <p>
            Unser Ansatz verbindet Kreativität mit strategischem Denken. So entstehen Designs, die nicht nur beeindrucken, sondern auch Ihre Zielgruppe langfristig ansprechen.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Erweiterte Marketingstrategien zur Umsatzsteigerung</h3>
          <p>
            Unsere maßgeschneiderten Marketingstrategien heben sich durch ihre Wirksamkeit und Kundennähe hervor. Durch die Kombination aus datengetriebenen Analysen und kreativen Ansätzen erzielen wir messbare Erfolge:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Content-Marketing: Hochwertige Inhalte, die Ihre Zielgruppe ansprechen und Ihr Unternehmen als Experten positionieren.</li>
            <li>E-Mail-Marketing: Direktes Targeting Ihrer Kunden mit personalisierten Kampagnen, die den Umsatz steigern.</li>
            <li>Kundenerfahrungen als Grundlage: Wir nutzen Feedback und Analysen, um die Strategie kontinuierlich zu verbessern.</li>
          </ul>
          <p>
            Mit einem ganzheitlichen Ansatz optimieren wir den Marketingmix, um Ihre Sichtbarkeit zu erhöhen und Ihre Unternehmensziele nachhaltig zu erreichen. Diese Strategien sind ideal für Unternehmen, die ihre Marke stärken und langfristig erfolgreich sein möchten.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Ganzheitliche Betreuung und Beratung</h3>
          <p>
            Als Full-Service-Agentur in Mainz bieten wir mehr als nur Einzeldienstleistungen. Unser Fokus liegt auf einer umfassenden Betreuung, die alle Aspekte Ihrer Online-Präsenz umfasst:
          </p>
          <ul className="list-disc pl-6 mb-4 mt-2">
            <li>Strategische Beratung: Entwicklung langfristiger Online-Strategien.</li>
            <li>Technische Umsetzung: Webdesign, SEO, Google Ads und mehr.</li>
            <li>Laufende Betreuung: Regelmäßige Updates, Optimierungen und Support.</li>
          </ul>
          <p>
            Wir verstehen uns als Ihr langfristiger Partner, der gemeinsam mit Ihnen an Ihrem Erfolg arbeitet.
          </p>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Kundenstimmen und Erfahrungsberichte</h3>
          <p>
            Detaillierte Kundenbewertungen geben Einblicke in die Zusammenarbeit mit ooliv und zeigen, wie wir Unternehmen zum Erfolg verhelfen. Unsere Kunden berichten von ihren Erfahrungen und dem Mehrwert, den unsere Dienstleistungen bieten:
          </p>
          <ul className="space-y-3 mt-4 mb-6">
            <li>
              <strong>Erfolg im Einzelhandel</strong> „Mit ooliv konnten wir unsere Online-Präsenz deutlich verbessern. Die Kombination aus SEO und Google Ads brachte uns innerhalb von drei Monaten 30 % mehr Website-Traffic." – Sandra K.
            </li>
            <li>
              <strong>Designprojekte: Maßgeschneiderte Lösungen</strong> „Das neue Webdesign von ooliv hat nicht nur unser Image modernisiert, sondern auch die Nutzerfreundlichkeit enorm gesteigert. Wir bekommen durchweg positives Feedback von unseren Kunden." – Michael R.
            </li>
            <li>
              <strong>Langjährige Partnerschaft mit ooliv</strong> „Wir arbeiten seit über 10 Jahren mit ooliv zusammen. Die Agentur versteht es, unsere Unternehmensziele mit kreativen und datengetriebenen Ansätzen zu unterstützen." – Julia F.
            </li>
          </ul>
          <p>
            Lesen Sie weitere Bewertungen auf unserer Google-Seite oder kontaktieren Sie uns, um mehr über unsere Erfolgsgeschichten zu erfahren.
          </p>

          <blockquote className="border-l-4 border-brand-primary pl-4 italic my-6">
            „Dank ooliv haben wir eine Website, die nicht nur gut aussieht, sondern auch funktioniert. Die Zusammenarbeit war von Anfang an professionell und angenehm." – Susanne B.
          </blockquote>

          <blockquote className="border-l-4 border-brand-primary pl-4 italic my-6">
            „Die SEO-Optimierung von ooliv hat uns geholfen, unsere Sichtbarkeit im Netz zu steigern. Jetzt werden wir bei Google besser gefunden." – Markus P.
          </blockquote>

          <blockquote className="border-l-4 border-brand-primary pl-4 italic my-6">
            „Wir schätzen die strategische Herangehensweise und die kreativen Ideen von ooliv. Die Ergebnisse sprechen für sich." – Stefanie S.
          </blockquote>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Häufig gestellte Fragen (FAQs)</h3>
          <div className="space-y-4 mt-4 mb-6">
            <div>
              <p><strong>Welche Dienstleistungen bietet ooliv als Werbeagentur Mainz an?</strong></p>
              <p>Wir bieten Webdesign, SEO, Google Ads, Branding und kanalübergreifende Online-Strategien.</p>
            </div>
            <div>
              <p><strong>Wie lange dauert die Entwicklung einer Website durch ooliv?</strong></p>
              <p>Abhängig von der Komplexität des Projekts zwischen 4 und 8 Wochen.</p>
            </div>
            <div>
              <p><strong>Warum sollte ich ooliv wählen?</strong></p>
              <p>Wir verbinden kreative Ansätze mit datengetriebener Effizienz und regionaler Marktkenntnis.</p>
            </div>
            <div>
              <p><strong>Welche Vorteile bietet lokale SEO?</strong></p>
              <p>Lokale SEO ermöglicht es Ihnen, gezielt Kunden in Mainz und Umgebung anzusprechen, Ihre Sichtbarkeit in der Region zu steigern und mehr Traffic zu generieren.</p>
            </div>
          </div>

          <h3 className="text-xl md:text-2xl font-bold text-gray-800 mt-8 mb-4">Fazit: Ihr Erfolg ist unser Ziel</h3>
          <p>
            Als Ihre Werbeagentur in Mainz begleiten wir Sie mit kreativen Ideen, innovativen Lösungen und datengetriebenen Strategien auf Ihrem Weg zum digitalen Erfolg. Kontaktieren Sie uns für ein unverbindliches Erstgesprääch und lassen Sie uns gemeinsam Ihre digitale Zukunft gestalten. Bei ooliv setzen wir auf Transparenz, Innovation und eine partnerschaftliche Zusammenarbeit, um Ihre Ziele zu erreichen. Starten Sie jetzt durch und werden Sie Teil unseres Erfolgsnetzwerks.
          </p>
        </article>
      </div>
    </section>
  );
};

export default SEOTextSection;
