
import React from 'react';
import MainNavigation from './navigation/MainNavigation';

const Navbar = () => {
  return (
    <div className="z-50">
      <MainNavigation />
    </div>
  );
};

export default Navbar;
